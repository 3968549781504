const groups = [
  {
    name: "Eaze's Flower and Prerolls",
    description: '',
    slug: 'mega-flower',
    displayName: 'flower',
    misspellings: [
      'weed',
      'pot',
      'ganja',
      'trees',
      'broccoli',
      'grass',
      'dope',
      'herb',
      'reefer',
      'chronic',
      'dank',
      'mary jane'
    ]
  },
  {
    name: "Eaze's Vapes",
    description: '',
    slug: 'mega-vape',
    displayName: 'vaporizer',
    misspellings: ['vape']
  },
  {
    name: "Eaze's Edibles",
    description: '',
    slug: 'Mega-Edible',
    displayName: 'edibles',
    misspellings: ['edibles', 'edis']
  },
  {
    name: 'Concentrates',
    description: 'Highly potent cannabis extracts — favored by advanced customers.',
    slug: 'concentrates',
    displayName: 'concentrates',
    misspellings: []
  },
  {
    name: 'Wellness, Tinctures, Capsules & CBD',
    description: 'Patches, Tinctures, Tablets, Bath, Body & more.',
    slug: 'drops',
    displayName: 'Wellness, drops, capsules',
    misspellings: []
  },
  {
    name: 'Accessories',
    description: 'Tools, add-ons, and gear to help you enjoy the moment.',
    slug: 'accessories',
    displayName: 'accessories',
    misspellings: []
  },
  {
    name: 'Social Equity Partners',
    description: 'Support these brands to help communities affected by the war on drugs.',
    slug: 'social-equity-brands',
    displayName: 'Social equity brands',
    misspellings: []
  },
  {
    name: 'Women-Owned Brands',
    description: 'Support ladies in cannabis — these brands are owned by women.',
    slug: 'women-owned',
    displayName: 'Women owned brands',
    misspellings: []
  }
]

export default groups
