/**
 * This is the address bar that displays the current activeLocation.
 * Tapping should open up the address select modal.
 */

import { Fragment, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import { getAddressLoading } from '@/redux/loading/selectors'
import { getActiveLocation, getInSampleLocation } from '@/redux/location/selectors'
import { breakpoint } from '@/theme'
import { TEST_IDS } from '@stories/modals/address/test/constants'

const AddressDisplay = () => {
  const loading = useSelector(getAddressLoading)
  const inSampleLocation = useSelector(getInSampleLocation)
  const address = useSelector(getActiveLocation)
  const [addressTitle, setAddressTitle] = useState('')

  useEffect(() => {
    if (address) {
      const CITY_ADDRESS_TYPES = ['locality', 'political']
      const enterAddressText = 'Please enter your address'

      if (inSampleLocation) {
        setAddressTitle(enterAddressText)
      } else if (!address.city && address.zip) {
        setAddressTitle(address.zip)
      } else if (address.types && address.types.some((type) => CITY_ADDRESS_TYPES.includes(type))) {
        setAddressTitle(address.city || enterAddressText)
      } else {
        setAddressTitle(address.title || enterAddressText)
      }
    }
  }, [address, inSampleLocation])

  if (!address) return null

  return (
    <AddressContainer className="fs-mask" data-e2eid={TEST_IDS.ADDRESS_PICKER}>
      {loading ? 'Updating location...' : <Fragment>{addressTitle}</Fragment>}
    </AddressContainer>
  )
}

const AddressContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  line-height: 1;
  @media (min-width: ${breakpoint.min.lg}px) {
    padding-left: 10px;
  }
`

export default AddressDisplay
