export default {
  primary: {
    1: '#00AAE7',
    2: '#5CC9F0',
    3: '#66CCF1',
    4: '#99DDF5',
    5: '#E3F6FD'
  },
  cbd: {
    1: '#9360A8',
    2: '#A980B9',
    3: '#BEA0CB',
    4: '#D4BFDC',
    5: '#E9DFEE'
  },
  hybrid: {
    1: '#42BC97',
    2: '#68C9AC',
    3: '#8ED7C1',
    4: '#B3E4D5',
    5: '#D9F2EA'
  },
  sativa: {
    0: '#FFE400',
    1: '#FACA18',
    2: '#FBD546',
    3: '#FCDF74',
    4: '#FDEAA3',
    5: '#FEF4D1',
    6: '#EBB22D'
  },
  indica: {
    1: '#126CAB',
    2: '#4189BC',
    3: '#71A7CD',
    4: '#A0C4DD',
    5: '#D0E2EE'
  },
  danger: {
    1: '#F05B4B',
    2: '#F37C6F',
    3: '#F69D93',
    4: '#F9BDB7',
    5: '#FCDEDB',
    6: '#E02020'
  },
  accessory: {
    1: '#5C515A',
    2: '#817D80',
    3: '#D2CDCC',
    4: '#E9E3E1',
    5: '#FFF8F5',
    6: '#171BC0',
    7: '#313131',
    8: '#000000'
  },
  rebrand: {
    1: '#181AC0'
  },
  homepageRedesign: {
    0: '#000000',
    1: '#FFE400',
    2: '#FFFFFF',
    3: '#0091FF',
    4: '#BBB4B8',
    5: '#00A6F0',
    6: '#FF0000',
    7: '#505050'
  },
  search: {
    1: '#AFB0B4',
    2: '#FFE404'
  },
  cityPage: {
    1: '#FFFFFF',
    2: '#000000',
    3: '#FFE404'
  }
}
