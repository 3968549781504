import Router from 'next/router'

export const navTypes = {
  PUSH: 'push',
  REPLACE: 'replace',
  POP: 'pop',
  BACK: 'back'
}

export default function navigation(action, webRoute) {
  switch (action) {
    case 'push':
      Router.push(webRoute)
      break

    case 'replace':
      Router.replace(webRoute)
      break

    case 'pop':
      Router.pop(webRoute)
      break

    case 'back':
      Router.back(webRoute)
      break

    default:
      throw new Error('unhandled action for Router')
  }
}
