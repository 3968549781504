import type { Group, GroupResponse } from '../../../models/bff/group'
import type { GroupRequest } from './logic'

import { ErrorResponse } from '../../../models/og/menu'
import { NEXTJS_API_PATH, WWW_BASE_URL } from '../../../utils/constants'

// Components will use this function to make client-side requests to /api/v2/groups/:slug
export default async function getClientSideGroup(request: GroupRequest): Promise<{ err: ErrorResponse; data: Group }> {
  let result = <GroupResponse>{}
  let statusCode: number

  let URL = `${WWW_BASE_URL}${NEXTJS_API_PATH}/groups/${request.slug}`
  URL += `?menu=${request.menu || 'default'}`
  if (request.placeId) URL += `&place_id=${request.placeId}`
  if (request.ignoreInventory) URL += `&ignore_inventory=${request.ignoreInventory.toString()}`

  try {
    const response = await fetch(URL)
    statusCode = response.status
    result = await response.json()
  } catch (err) {
    console.error(`Error fetching group ${JSON.stringify(request)}`, err)
    return { err: { message: err?.message, statusCode } as ErrorResponse, data: null }
  }
  if ('message' in result) {
    return { err: { message: result.message, statusCode } as ErrorResponse, data: null }
  }
  return { err: null, data: result }
}
