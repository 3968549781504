import { Menu, Product } from '@bff/models/og/menu'

const SEARCHABLE_PRODUCT_FIELDS = [
  'id',
  'name',
  'photoUrl',
  'type',
  'species',
  'brand',
  'description',
  'highCBD',
  'isAccessory',
  'slug',
  'catalogItemId',
  'price',
  'type',
  'subtype'
] as const

export type SearchableProduct = Pick<Product, typeof SEARCHABLE_PRODUCT_FIELDS[number]>

export function extractProductsFromMenu(menu: Menu): [Record<string, Product>, Record<string, SearchableProduct>] {
  const products = {}
  const searchableProducts = {}

  menu?.groups?.forEach((group) => {
    group?.products?.forEach((product) => {
      searchableProducts[product.id] = pick(product, SEARCHABLE_PRODUCT_FIELDS)
      products[product.id] = product
    })
  })

  return [products, searchableProducts]
}

function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}
