import { Brand } from 'bff/models/og/brand'
import { Product } from 'bff/models/og/menu'

export function extractBrandsFromProducts(productsMap: Record<string, Product>): Partial<Brand[]> {
  if (Object.keys(productsMap).length === 0) {
    return []
  }

  const brands = {}
  Object.values(productsMap).map((product) => (brands[product.brand.id] = product.brand))

  return Object.values(brands)
}
