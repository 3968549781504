export const TEST_IDS = {
  COMPONENT: 'newNavbarComponent',
  LOGIN_LINK: 'newNavbarLoginLink',
  BACK_BUTTON: 'newNavbarBackButton',
  OPEN_CART_BUTTON: 'newNavbarCartButton',
  TOGGLE_SIDEBAR_BUTTON: 'newNavbarToggleSidebarButton',
  FADED_TOGGLE_SIDEBAR_BUTTON: 'fadedNavbarToggleSidebarButton',
  FADED_GOTO_LOGIN_BUTTON: 'fadedNavbarGotoLoginButton',
  FADED_OPEN_CART_BUTTON: 'fadedNavbarOpenCartButton',
  SEARCH_BUTTON: 'navbarSearchButton'
}
