export const TEST_IDS = {
  ADDRESS_PICKER: 'addressPicker',
  ERROR_MESSAGE: 'addressErrorMessage',
  AUTOSUGGEST_INPUT: 'autoSuggestInput',
  AUTOSUGGEST_ERROR_MESSAGE: 'autoSuggestErrorMessage',
  SAN_DIEGO_ADDRESS: '1750 Fiesta Island Rd, San Diego',
  MICHIGAN_COMMERCIAL_ADDRESS: '1777 3rd Ave, Detroit',
  MICHIGAN_RESIDENTIAL_ADDRESS: '5536 Martin St, Detroit',
  NORTH_DAKOTA_ADDRESS: 'north dakota',
  SAN_FRANCISCO_ADDRESS: '4 Embarcadero Ctr, San Francisco'
}
