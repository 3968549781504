import { Product } from '@bff/models/og/menu'

import t from './actionTypes'

export const setProducts = (payload: Record<string, Product>) => (dispatch) => {
  // create a map so we can translate catalogItemId's into legacy/deprecated product.id's
  const idMap = Object.entries(payload).reduce((accumulator, [key, value]) => {
    if (value.catalogItemId && value.id) {
      accumulator[value.catalogItemId] = value.id
    }

    return accumulator
  }, {})

  // set products in product store
  dispatch(setProductList(payload))
  dispatch(setProductIdMap(idMap))
}

const setProductList = (payload) => ({
  type: t.SET_PRODUCTS,
  payload
})

const setProductIdMap = (payload) => ({
  type: t.SET_PRODUCT_ID_MAP,
  payload
})
